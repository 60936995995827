// Our main CSS
import '../css/app.css'
import Alpine from 'alpinejs'
declare global {
  interface Window {
    WORK_POSTS_ENDPOINT: string
    EMPLOYEE_POSTS_ENDPOINT: string
  }
}
window.Alpine = Alpine

const initNonCritical = async () => {
  // Site menu initialization

  const loadComponents = [
    // Site menu
    import('./modules/site-menu').then(({ default: site_menu }) => {
      Alpine.data('site_menu', site_menu)
    }),

    // Work archive form
    new Promise<void>((resolve) => {
      const work_archive_form = document.querySelector('.WorkArchive-Form')
      if (work_archive_form) {
        import('./modules/work-archive-filter').then(({ default: work_archive_form }) => {
          Alpine.data('work_archive_form', work_archive_form)
          resolve()
        })
      } else {
        resolve()
      }
    }),

    new Promise<void>((resolve) => {
      const employees_archive_form = document.querySelector('.EmployeesArchive-Form')
      if (employees_archive_form) {
        import('./modules/employees-archive-filter').then(({ default: employees_archive_form }) => {
          Alpine.data('employees_archive_form', employees_archive_form)
          resolve()
        })
      } else {
        resolve()
      }
    })
  ]
  await Promise.all(loadComponents)

  // Start Alpine after all components are registered
  Alpine.start()

  import('./modules/color-scheme').then(({ default: init }) => {
    init()
  })

  // Lazy load video handling
  const autoplaying_videos = document.querySelectorAll<HTMLVideoElement>('video[playsinline]')
  if (autoplaying_videos?.length) {
    import('./modules/inline-videos').then(({ default: handleVideos }) => {
      handleVideos(autoplaying_videos)
    })
  }

  const slideshows = document.querySelectorAll<HTMLElement>('.SimpleImageSlideshow')
  if (slideshows?.length) {
    import('./modules/simple-image-slideshow').then(({ default: handleSlideshows }) => {
      handleSlideshows(slideshows)
    })
  }

  const lottie_elements = document.querySelectorAll<HTMLElement>('[data-lottie]')
  if (lottie_elements?.length) {
    import('./modules/lottie/lottie').then(({ default: setupLottieAnim }) => {
      setupLottieAnim(lottie_elements)
    })
  }

  const site_logo = document.querySelector('.SiteLogo') as HTMLElement
  if (site_logo) {
    import('./modules/site-logo').then(({ default: init }) => {
      init(site_logo)
    })
  }

  const check_intersecting = document.querySelectorAll(
    '[data-check-intersecting]'
  ) as NodeListOf<HTMLElement>

  if (check_intersecting?.length) {
    import('./modules/check-intersection').then(({ default: init }) => {
      init(check_intersecting)
    })
  }
}

if ('requestIdleCallback' in window) {
  requestIdleCallback(() => initNonCritical())
} else {
  // Fallback for browsers that don't support requestIdleCallback
  setTimeout(initNonCritical, 1)
}
